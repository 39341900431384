import React from "react";

function ErrorPage() {
    return (
        <div className="errorPage">
            <h1>ErrorPage</h1>
        </div>
    );
}

export default ErrorPage;