export const MenuItems = [
    {
        title: 'Home',
        url: '',
        shortName: 'nav-links'
    },
    {
        title: 'FAQ',
        url: 'faq',
        shortName: 'nav-links'
    },
    {
        title: 'Contact Us',
        url: 'contact',
        shortName: 'nav-links'
    },
]
/*
TODO: add these pages back
{
    title: 'Careers',
    url: 'careers',
    shortName: 'nav-links'
},


*/